<template>
    <div class="">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-copy"></i> 我的消息</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="message">
                <el-tab-pane :label="`未读消息(${unread.length})`" name="first">
                    <el-table :data="unread" :show-header="false" style="width: 100%">
                        <el-table-column>
                            <template #default="scope">
                                <span class="message-title">{{scope.row.remark}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rowTime" width="180"></el-table-column>
                        <el-table-column width="120">
                            <template #default="scope">
                                <!-- <el-button size="small" @click="handleRead(scope.$index)">标为已读</el-button> -->
                                <el-button size="small" @click="handleRead(scope.row,scope.$index)">标为已读</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="handle-row">
                        <!-- <el-button type="primary">全部标为已读</el-button> -->
                    </div>
                </el-tab-pane>
                <el-tab-pane :label="`已读消息(${read.length})`" name="second">
                    <template v-if="message === 'second'">
                        <el-table :data="read" :show-header="false" style="width: 100%">
                            <el-table-column>
                                <template #default="scope">
                                    <span class="message-title">{{scope.row.remark}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="rowTime" width="160"></el-table-column>
                            <!-- <el-table-column width="120">
                                <template #default="scope">
                                    <el-button type="danger" @click="handleDel(scope.$index)">删除</el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <!-- <div class="handle-row">
                            <el-button type="danger">删除全部</el-button>
                        </div> -->
                    </template>
                </el-tab-pane>
                <!-- <el-tab-pane :label="`回收站(${recycle.length})`" name="third">
                    <template v-if="message === 'third'">
                        <el-table :data="recycle" :show-header="false" style="width: 100%">
                            <el-table-column>
                                <template #default="scope">
                                    <span class="message-title">{{scope.row.title}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="date" width="150"></el-table-column>
                            <el-table-column width="120">
                                <template #default="scope">
                                    <el-button @click="handleRestore(scope.$index)">还原</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="handle-row">
                            <el-button type="danger">清空回收站</el-button>
                        </div>
                    </template>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getCurrentDate} from "@/utils/time";
import {setMessageRead} from "@/api/operate";
import {getToken} from "@/utils/auth";

    export default {
        name: 'tabs',
        data() {
            return {
                message: 'first',
                showHeader: false,
                // unread: [{
                //     date: '2018-04-19 20:00:00',
                //     title: '【系统通知】该系统将于今晚凌晨2点到5点进行升级维护',
                // },{
                //     date: '2018-04-19 21:00:00',
                //     title: '今晚12点整发大红包，先到先得',
                // }],
                // read: [{
                //     date: '2018-04-19 20:00:00',
                //     title: '【系统通知】该系统将于今晚凌晨2点到5点进行升级维护'
                // }],
                unread: [],
                read:[],
                recycle: [{
                    date: '2018-04-19 20:00:00',
                    title: '【系统通知】该系统将于今晚凌晨2点到5点进行升级维护'
                }]
            }
        },
        async created() {
				await this.onSearch();
		},
        computed: {
            // unreadNum(){
            //     return this.unreadMessageList.length;
            // },
            ...mapGetters(['unreadMessageList','readedMessageList'])
        },

        methods: {
            async handleRead(row,index) {                
                await setMessageRead({userId: getToken(), msgItemId: row.msgItemId}).then(()=>{
                    const item = this.unread.splice(index, 1);
                    // console.log(item);
                    this.read = item.concat(this.read);
                });
								// item.status = '已读';
								// this.$emit('after-look')
            },
            handleDel(index) {
                const item = this.read.splice(index, 1);
                this.recycle = item.concat(this.recycle);
            },
            handleRestore(index) {
                const item = this.recycle.splice(index, 1);
                this.read = item.concat(this.read);
            },

            onSearch() {
				// const {dataArray: [sDate, eDate], status, msgType} = this.searchForm;
                const eDate = getCurrentDate();
				this.$store.dispatch('operate/unreadMessageList', {sDate:'2022-3-1', eDate, force: true}).then(()=>{
                    this.unread = this.unreadMessageList
                });
                this.$store.dispatch('operate/readedMessageList', {sDate:'2022-3-1', eDate, force: true}).then(()=>{
                    this.read = this.readedMessageList
                });
			},

        },
        
    }

</script>

<style>
.message-title{
    cursor: pointer;
}
.handle-row{
    margin-top: 30px;
}
</style>

